import React from 'react'
import styled from '@emotion/styled'
import { MetaData } from '@jelly/components'

import AdvertiseLayout from '../_components/AdvertiseLayout'
import AdvertiseForm from '../_components/AdvertiseForm'
import SalesPitch from './_components/SalesPitch'
import BodyRight from './_components/BodyRight'

const MainContainer = styled.main`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 0px 10px 70px;
	position: relative;

	${({ theme }) => theme.MQ.XL} {
		flex-direction: row;
		justify-content: center;
	}
`

const LeftMain = styled.div`
	margin-bottom: -20px;
	text-align: center;

	${({ theme }) => theme.MQ.XL} {
		height: 600px;
		margin-bottom: 0px;
		margin-right: -20px;
		padding-bottom: 0px;
		width: 330px;
	}
`

const CenterMain = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 360px;
	position: relative;
	width: 100%;
	z-index: 1;

	${({ theme }) => theme.MQ.S} {
		max-width: 673px;
		width: 100%;
	}

	${({ theme }) => theme.MQ.XL} {
		height: 666px;
		justify-content: space-around;
		max-width: 475px;
		min-width: 475px;
	}
`

const RightMain = styled.div`
	margin-top: -8px;
	max-width: 365px;
	text-align: center;
	width: 100%;

	${({ theme }) => theme.MQ.S} {
		max-width: 675px;
	}

	${({ theme }) => theme.MQ.XL} {
		align-self: center;
		height: 600px;
		max-width: 328px;
		margin-left: -5px;
	}
`

const AdvertiseMetaData = {
	title: 'Acquire more customers with business.com advertising',
	canonical: `${process.env.GATSBY_SITE_HOST}/connect/v2/`,
	description:
		' business.com, the leader in online B2B lead generation, delivers high quality marketing programs with the ROI needed to help your business grow.',
}

const v2 = () => {
	return (
		<AdvertiseLayout>
			<MetaData meta={AdvertiseMetaData} />
			<MainContainer>
				<LeftMain>
					<SalesPitch />
				</LeftMain>
				<CenterMain>
					<AdvertiseForm src="https://connect.business.com/l/900901/2021-10-19/4mzgb" version="v2" />
				</CenterMain>
				<RightMain>
					<BodyRight />
				</RightMain>
			</MainContainer>
		</AdvertiseLayout>
	)
}

export default v2
